import React from 'react';
import logo from './img/Rome-Academy.png';
import './App.css';

function Home() {
  return (
    <div className="App">
      <body className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          House of Sempronia invites you to join the Rome Academy
        </p>
        <JoinButton/>
        </body>
    </div>
        );
}

function JoinButton() {
  return(
        <button className="bg-white text-red-600  font-bold p-4 mt-5">
            <a
              className="App-link"
              href="https://discord.com/invite/9HFwFVnkss"
              target="_blank"
              rel="noopener noreferrer"
           >
             <span>Join Discord</span>
          </a>
        </button>
         );
}


export default Home;
