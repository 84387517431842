import React from 'react';
import JSlogo from './img/JS.png';
import ReactLogo from './img/React.png';
import RustLogo from './img/Rust.png';
import TailwindCSSLogo from './img/Tailwind_CSS.png';
import SolidityLogo from './img/Solidity.png';
import ThreeJSLogo from './img/Three.png';
import QuestionsLogo from './img/Questions.png';

function Resources() {
  return (
    <>
    <div className="flex items-center justify-center mt-5 RedTEXT">
        <h1>Resources</h1>
    </div>
    <div className="flex items-center justify-center max-w-screen-xl m-auto">
          <div className="p-4">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 text-white text-center drop-shadow-lg">
                  <div className="RedBG p-5 rounded drop-shadow-lg">
                      <img src={JSlogo} alt="js" width="100px;" className="m-auto"></img>
                      <h2>Javascript</h2>
                      <ul className="text-gray-200 font-regular text-sm">
                          <li><a target="_blank" rel="noopener noreferrer" href="https://www.theodinproject.com/paths/full-stack-javascript/courses/javascript">The Odin Project</a></li>
                          <li><a target="_blank" rel="noopener noreferrer" href="https://www.freecodecamp.org/learn/javascript-algorithms-and-data-structures/">freeCodeCamp.org</a></li>
                          <li><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=PkZNo7MFNFg&ab_channel=freeCodeCamp.org">freeCodeCamp.org (Youtube)</a></li>
                          <li><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=iWOYAxlnaww&list=PL4cUxeGkcC9haFPT7J25Q9GRB_ZkFrQAc&ab_channel=TheNetNinja">The Net Ninja Tutorial #1-#6</a></li>
                          <li><a target="_blank" rel="noopener noreferrer" href="https://ethereum.org/en/developers/tutorials/">ethereum.org/en/developers/tutorials</a></li>
                      </ul>
                  </div>
                  <div className="RedBG p-5 rounded drop-shadow-lg">
                      <img src={ThreeJSLogo} alt="js" width="100px;" className="m-auto"></img>
                      <h2>Three.js</h2>
                      <ul className="text-gray-200 font-regular text-sm">
                          <li><a target="_blank" rel="noopener noreferrer" href="https://threejs-journey.com/">threejs-journey.com</a></li>
                          <li><a target="_blank" rel="noopener noreferrer" href="https://threejs.org/docs/#manual/en/introduction/Creating-a-scene">Three.js docs</a></li>
                      </ul>
                  </div>
                  <div className="RedBG p-5 rounded drop-shadow-lg">
                      <img src={ReactLogo} alt="js" width="100px;" className="m-auto"></img>
                      <h2>React</h2>
                      <ul className="text-gray-200 font-regular text-sm">
                          <li><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=F8s4Ng-re0E&ab_channel=TelmoSampaio">React Tutorial #1 (Youtube)</a></li>
                          <li><a target="_blank" rel="noopener noreferrer" href="https://www.freecodecamp.org/learn/javascript-algorithms-and-data-structures/">React + Tailwind Tutorial #2 (Youtube)</a></li>
                          <li><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=j942wKiXFu8&list=PL4cUxeGkcC9gZD-Tvwfod2gaISzfRiP9d&ab_channel=TheNetNinja">The Net Ninja Tutorial #1-#32 (Youtube)</a></li>
                          <li><a target="_blank" rel="noopener noreferrer" href="https://reactjs.org/tutorial/tutorial.html#what-is-react">React Docs and Tutorial</a></li>
                      </ul>
                  </div>
                  <div className="RedBG p-5 rounded drop-shadow-lg">
                      <img src={TailwindCSSLogo} alt="js" width="100px;" className="m-auto"></img>
                      <h2>Tailwind CSS</h2>
                      <ul className="text-gray-200 font-regular text-sm">
                          <li><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=bxmDnn7lrnk&list=PL4cUxeGkcC9gpXORlEHjc5bgnIi5HEGhw&ab_channel=TheNetNinja">The Net Ninja Tutorial #1-#19 (Youtube)</a></li>
                          <li><a target="_blank" rel="noopener noreferrer" href="https://www.freecodecamp.org/learn/javascript-algorithms-and-data-structures/">React + Tailwind Tutorial (Youtube)</a></li>
                          <li><a target="_blank" rel="noopener noreferrer" href="https://tailwindcss.com/docs/installation">Tailwind CSS Docs and Setup</a></li>
                      </ul>
                  </div>
                  <div className="RedBG p-5 rounded drop-shadow-lg">
                      <img src={SolidityLogo} alt="js" width="100px;" className="m-auto"></img>
                      <h2>Solidity</h2>
                      <ul className="text-gray-200 font-regular text-sm">
                          <li><a target="_blank" rel="noopener noreferrer" href="https://cryptozombies.io/">cryptozombies.io</a></li>
                          <li><a target="_blank" rel="noopener noreferrer" href="https://buildspace.so/solidity">buildspace.so/solidity</a></li>
                          <li><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=M576WGiDBdQ&ab_channel=freeCodeCamp.org">freeCodeCamp.org (Youtube 16hrs)</a></li>
                          <li><a target="_blank" rel="noopener noreferrer" href="https://ethereum.org/en/developers/tutorials/">ethereum.org/en/developers/tutorials</a></li>
                      </ul>
                  </div>
                  <div className="RedBG p-5 rounded drop-shadow-lg">
                      <img src={RustLogo} alt="js" width="100px;" className="m-auto"></img>
                      <h2>Rust</h2>
                      <ul className="text-gray-200 font-regular text-sm">
                          <li><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=zF34dRivLOw&ab_channel=TraversyMedia">Rust Tutorial #1 (Youtube)</a></li>
                          <li><a target="_blank" rel="noopener noreferrer" href="https://www.rust-lang.org/learn">Rust Docs</a></li>
                      </ul>
                  </div>
                  <div className="RedBG p-5 rounded drop-shadow-lg col-span-1 md:col-span-2">
                      <img src={QuestionsLogo} alt="js" width="100px;" className="m-auto"></img>
                      <h2>Questions</h2>
                      <p className="text-gray-200 font-regular text-sm">If you have any questions please join the discord server and navigate to the #library channel. Also, please let us know if there are any additional resources that you think should be posted.</p>
                  </div>
              </div>
          </div>
      </div></>
        );
}

export default Resources;