import React, { useState, useEffect } from 'react';
import './App.css';
import Resources from './Resources';
import Home from './Home';
import Navbar from './Navbar';
import Dropdown from './Dropdown';
import {HashRouter as Router, Route, Switch, IndexRouteProps, Link} from 'react-router-dom';

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  
  return (
    <>
     <Navbar toggle={toggle}/>
     <Dropdown isOpen={isOpen} toggle={toggle}/>
     <Router >
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/resources" component={Resources} />
        </Switch>
     </Router>
     </>
  );
}

export default App;
