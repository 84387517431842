import React from 'react';
import {Link} from 'react-router-dom';
import logo from './img/Rome-Academy.png';

function Navbar({ toggle }) {
    return(
        <nav className="RedBG flex justify-between items-center h-20 drop-shadow-lg">
           <a
              className="App-link pl-8"
              href="/"
           >
                 <img src={logo} alt="Rome_Academy" width="100px" className="p-6"/>
            </a>
            <div className="px-4 cursor-pointer md:hidden" onClick={toggle}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
            </div>
            <div className="pr-8 md:block hidden text-white">
                <a className="p-4" href="/">Home</a>
                <a className="p-4" href="https://rome-academy.com/#/resources">Resources</a>
                <button className="bg-white text-red-600  font-bold p-2 mt-2 drop-shadow-lg ">
                    <a className="p-4" href="https://discord.com/invite/9HFwFVnkss" target="_blank"
              rel="noopener noreferrer">Join Discord</a>
                </button>
            </div> 
       </nav>
    )
}

export default Navbar;
