import React from "react";
import { Link } from "react-router-dom";

const Dropdown = ({isOpen, toggle }) => {
    return(
        <div className={isOpen ? 'grid grid-row-4 text-center items-center RedBG text-white pb-4' : 'hidden'} onClick={toggle}>
            <a className="p-4" href="/">Home</a>
                <a className="p-4" href="https://rome-academy.com/#/resources">Resources</a>
                <button className="bg-white text-red-600  font-bold p-2 mt-2 drop-shadow-lg w-40 items-center m-auto">
                    <a className="p-4" href="https://discord.com/invite/9HFwFVnkss" target="_blank"
              rel="noopener noreferrer">Join Discord</a>
                </button>
        </div>
    )
}

export default Dropdown